import React, { useState } from "react";
import { Calendar, Tag, Space, Modal, Row, Col } from "antd";
import "./calender.less";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { actions, historySelectors } from "../../store/history";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";

const CalenderForm = (props: any) => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(historySelectors.getAttendanceReport);
  
  const userKey = Object.keys(history !== null && history)[0];

  const getListData = (value: any) => {
    let listData: any = [];

    Object.entries(
      history !== null && history[userKey].attendace_data!
    )?.forEach((val:any) => {
      if (value.format("YYYY-MM-DD") === val[1].date) {
        listData = [
          {
            key: val[0],
            status: val[1].status,
            checkin: val[1].check_in + " to " + val[1].check_out,
          },
        ];
      }
    });
    return listData || [];
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dateCellRender = (value: any) => {
    const listData = getListData(value);
    return (
      <>
        <ul className="events">
          {listData?.map((item: any) => (
            <li key={item.key}>
              {item.status === "Present" ||
              item.status === "Adjustment Leave" ||
              item.status === "Casual Leave" ||
              item.status === "Sick Leave" ||
              item.status === "Earned Leave" ? (
                <Space direction="vertical">
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    {item.status}
                  </Tag>
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    {item.checkin}
                  </Tag>
                </Space>
              ) : item.status === "Half-day" ||
                item.status === "Half-day (CO forgot)" ? (
                <Space direction="vertical">
                  <Tag icon={<FieldTimeOutlined />} color="warning">
                    {item.status}
                  </Tag>

                  <Tag icon={<ClockCircleOutlined />} color="default">
                    {item.checkin}
                  </Tag>
                </Space>
              ) : item.status === "Saturday" ? (
                <Tag icon={<FieldTimeOutlined />} color="success">
                  Present
                </Tag>
              ) : (
                item.status === "Absent" && (
                  <>
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                      onClick={showModal}
                    >
                      {item.status}
                    </Tag>
                    <Modal
                      title="Absent details"
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      <Row gutter={[14, 14]}>
                        <Col span={12}>Start Date</Col>
                        <Col span={12}>{item.start_date}</Col>
                        <Col span={12}>End Date</Col>
                        <Col span={12}>28 Apr 2022</Col>
                        <Col span={12}>Reason</Col>
                        <Col span={12}>
                          Internship review (LD Kaizen event - offline) and
                          Departmental farewell event.
                        </Col>
                        <Col span={12}>Type</Col>
                        <Col span={12}>Adjustment Leave (Emergency)</Col>
                        <Col span={12}>Adjustment Date</Col>
                        <Col span={12}>30 Apr, 202 2</Col>
                      </Row>
                    </Modal>
                  </>
                )
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <Calendar
      style={{minWidth:"1000px"}}
      dateCellRender={dateCellRender}
      onPanelChange={(value) => {
        const startOfMonth = value
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = value.clone().endOf("month").format("YYYY-MM-DD");
        dispatch(
          actions.attendanceReport({
            user_id: props.userId,
            start: startOfMonth,
            end: endOfMonth,
          })
        );
      }}
    />
  );
};

export default CalenderForm;
