import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,

  Row,
  Select,
  Space,
  Upload,
} from "antd";
import moment from "moment";
import React, { FC, useState } from "react";
import { actions, historySelectors } from "../../store/history";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userSelectors } from "../../store/users";

import CalenderReport from "./calender";
import ExportAttendanceForm from "./ExportReport/ExportAttendanceForm";
const { Option } = Select;


const AttendanceSection:FC = ()=> {

  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(userSelectors.getUserDetails);
  const loader = useAppSelector(historySelectors.uploadLogLoader);
  
  const [userId, setuserId] = useState("-");
  const [userName, setuserName] = useState("-");
  const [modelVisible, setmodelVisible] = useState(false);
  function UserSelection(id: string) {
    setuserId(id);
    userDetails?.map((user)=>{
     return user.id===parseInt(id) && setuserName(user.name)
    })
    const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD"); //fetch start of date for this month
    const endOfMonth = moment().clone().endOf("month").format("YYYY-MM-DD"); //fetch end of date for this month
    dispatch(
      actions.attendanceReport({
        user_id: id,
        start: startOfMonth,
        end: endOfMonth,
      })
    );
    
  }
  return (
    <div>
      <Row justify={"space-between"} gutter={[12, 12]}>
        <Col>
          <Select
            size={"middle"}
            defaultValue="Select User"
            onChange={UserSelection}
            style={{ width: 200 }}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {userDetails?.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Col>
        {/* <Col>
          <RangePicker/></Col> */}
        <Col>
          <Space>
            <Upload 
            showUploadList={false}
            beforeUpload={file => {
              const reader = new FileReader();
              
              reader.readAsDataURL(file);
              reader.onload = () => {
                dispatch(actions.uploadLog({
                  fileName: file.name,
                  fileExtension:file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2),
                  value: (reader.result as string).split(',')[1]
                }))
              };
              
          
          }}
            accept=".dat">
              <Button type="primary"
              loading= {loader}
              icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              
              onClick={() => {
                setmodelVisible(true)  
               }}
               >
              Download
            </Button>
            <ExportAttendanceForm visible={modelVisible} onClose={() => setmodelVisible(false)} />
          </Space>
        </Col>
      </Row>
      <br />

      <div
        style={{
          background: "#FAFAFA",
          padding: 18,
        }}
      >
        <h5>{userName}</h5>
        <Divider></Divider>
        <CalenderReport userId={userId}></CalenderReport>
      </div>
    </div>
  );
}

export default AttendanceSection;
